import { createApp } from "vue";
import { createPinia } from "pinia";
import Select2 from 'vue3-select2-component';

import './utils/axios';
import "./registerServiceWorker";

import router from "./router";

import '../node_modules/nprogress/nprogress.css';
import '../node_modules/leaflet/dist/leaflet.css';
import '../node_modules/leaflet-geosearch/dist/geosearch.css';

import "@/assets/main/mandatory.js";
import "@/assets/main/vendor.init.js";
import "@/assets/main/datatables.bundle.js";
import "@/assets/main/widgets.bundle.js";

import "@/assets/main/datatable.css";
import "@/assets/plugins/plugins.bundle.css";
import "@/assets/main/style.bundle.css";

import App from "./App.vue";

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.component('Select2', Select2);
app.mount("#app");
