import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuth = defineStore('auth-store', () => {
    const token    = ref(localStorage.getItem('token'));
    var   CryptoJS = require("crypto-js");

    const login = async (credentials) => {
        const response  = await axios.post('account/signin', credentials);
        const data      = response.data.data;
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
        localStorage.setItem('tc::', data.access_token);
        return await getScope();
    };

    const getScope = async () => {
        try {
            let response  = await axios.get('account/scopes');
            var enc_scope = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), process.env.VUE_APP_SECRET_KEY).toString();
            localStorage.setItem('sc::', enc_scope);
            window.location.href = `${window.location.origin}`;
        } catch (e) {
            return await logout();
        }
    };

    const getToken = async () => {
        return localStorage.getItem('tc::');
    };

    const logout = async () => {
        try {
            await axios.get("account/signout");
            localStorage.removeItem('tc::');
            localStorage.removeItem('sc::');
            window.location.href = `${window.location.origin}/login`;
        } catch (e) {
            localStorage.removeItem('tc::');
            localStorage.removeItem('sc::');
            window.location.href = `${window.location.origin}/login`;
        }
    };

    return {
        login,
        logout,
        getToken,
        getScope,
        token
    };
});