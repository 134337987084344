import { defineStore } from "pinia";
import { reactive } from "vue";

export const useScope = defineStore('scope-store', () => {

    var CryptoJS        = require("crypto-js");
    if (localStorage.getItem('sc::') !== null) {
        var bytes         = CryptoJS.AES.decrypt(localStorage.getItem('sc::'), process.env.VUE_APP_SECRET_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    const scopes      = reactive(decryptedData);
    const fors = (role, level) => {

        if (role === 'ALL' && level === 'ALL') {
            return true;
        }

        const roles  = role.split(".");
        const levels = level.split(".");
        return (typeof scopes !== 'undefined' && roles.includes(scopes.role.toUpperCase()) && levels.includes(scopes.level.toUpperCase())) 
            ? true 
            : false;
    }

    const get = () => {
        return scopes;
    }

    const level = () => {
        return scopes.level;
    }

    const role = () => {
        return scopes.role;
    }

    return {
        fors,
        get,
        level,
        role
    };
});