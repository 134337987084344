import axios from "axios";
let base_url;

switch (process.env.VUE_APP_NODE_ENV) {
    case 'development':
        base_url = process.env.VUE_APP_DEV_SERVICE_URL
        break;
    case 'production':
        base_url = process.env.VUE_APP_PROD_SERVICE_URL
        break;
    default:
        base_url = process.env.VUE_APP_LOCAL_SERVICE_URL
        break;
}

axios.defaults.baseURL = base_url;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('tc::')}`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';