import {
	createRouter,
	createWebHashHistory,
	createWebHistory
} from 'vue-router';
import NProgress from 'nprogress'
import {
	useAuth
} from '@/stores/auth';
import {
	useScope
} from '@/stores/scopes';

const version = process.env.VUE_APP_VERSION;
const routes = [
	{
		path: '/login',
		redirect: {
			name: 'signin'
		}
	},
	{
		path: `/signin`,
		redirect: {
			name: 'signin'
		},
		component: () => import('../views/AuthViews.vue'),
		children: [{
				path: ``,
				redirect: {
					name: 'signin'
				},
				meta: {
					authPage: true
				}
			},
			{
				path: `${version}`,
				redirect: {
					name: 'signin'
				},
				children: [{
					path: `identifier`,
					name: 'signin',
					component: () => import('../components/auth/Login.vue'),
					meta: {
						authPage: true,
						title:'Login'
					}
				}],
			}
		]
	},
	{
		path: '',
		component: () => import('../views/HomeViews.vue'),
		redirect: {
			name: 'dashboard'
		},
		children: [
			{
				path: `/`,
				redirect: {
					name: 'dashboard'
				},
				meta: {
					requireAuth: true,
				},
				props: true
			},
			{
				path: `/${version}`,
				redirect: {
					name: 'dashboard'
				},
				children: [
					{
						path: `dashboard`,
						name: 'dashboard',
						component: () => import('@/components/dashboard/Home.vue'),
						meta: {
							requireAuth: true,
							role:'ALL',
							level:'ALL',
							title: 'Dashboard'
						},
						props: true
					},
					{
						path: `account/settings`,
						name: 'account_settings',
						component: () => import('@/components/account/Settings.vue'),
						meta: {
							requireAuth: true,
							role:'ALL',
							level:'ALL',
							title: 'Pengaturan Akun'
						},
						props: true
					},
					{
						path: `users`,
						component: () => import('@/components/users_management/Main.vue'),
						redirect: {
							name: 'users'
						},
						children: [
							{
								path: ``,
								name: 'users',
								component: () => import('@/components/users_management/users/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR',
									title: 'Pengguna'
								},
								props: true
							}, 
							{
								path: `create`,
								name: 'users_create',
								component: () => import('@/components/users_management/users/Create.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR',
									title: 'Pengguna'
								},
								props: true
							}, 
							{
								path: `update/:id`,
								name: 'users_update',
								component: () => import('@/components/users_management/users/Update.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR',
									title: 'Pengguna'
								},
								props: true
							}, 
							{
								path: `:id/reset-password`,
								name: 'users_reset_password',
								component: () => import('@/components/users_management/users/ResetPassword.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR',
									title: 'Pengguna'
								},
								props: true
							}, 
						]
					},
					{
						path: `district`,
						component: () => import('@/components/regions/Main.vue'),
						redirect: {
							name: 'district'
						},
						children: [
							{
								path: ``,
								name: 'district',
								component: () => import('@/components/regions/Districts.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR',
									title: 'Wilayah Kabupaten'
								},
								props: true
							},
							{
								path: `:id/subdistrict`,
								name: 'subdistrict',
								component: () => import('@/components/regions/SubDistricts.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR',
									title: 'Wilayah Kapanewon'
								},
								props: true
							}, 
							{
								path: `:district_id/subdistrict/:subdistrict_id/village`,
								name: 'village',
								component: () => import('@/components/regions/Village.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR',
									title: 'Wilayah Kalurahan'
								},
								props: true
							}, 
						]
					},
					{
						path: `proposal`,
						component: () => import('@/components/proposal/Main.vue'),
						redirect: {
							name: 'proposal'
						},
						children: [
							{
								path: ``,
								name: 'proposal',
								component: () => import('@/components/proposal/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `create`,
								name: 'proposal_create',
								component: () => import('@/components/proposal/Create.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `:id/update`,
								name: 'proposal_update',
								component: () => import('@/components/proposal/Update.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'proposal_detail',
								component: () => import('@/components/proposal/Detail.vue'),
								meta: {
									requireAuth: true,
									role:'ALL',
									level:'ALL',
									title: 'Proposal'
								},
								props: true
							},
						]
					},
					{
						path: `proposal/recap`,
						redirect:{name:'proposal_recap'},
						children: [
							{
								path:'',
								name: 'proposal_recap',
								component: () => import('@/components/proposal/Recap.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true,
							},
							{
								path: `:id`,
								name: 'proposal_recap_detail',
								component: () => import('@/components/proposal/Detail.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
						]
					},
					{
						path: `verification`,
						redirect: {
							name: 'administrative_verification'
						},
						children: [
							{
								path: `administration`,
								redirect: {
									name: 'list_administrative_verification'
								},
								component: () => import('@/components/verification/administrative/Main.vue'),
								children: [
									{
										path: ``,
										name: 'list_administrative_verification',
										component: () => import('@/components/verification/administrative/Home.vue'),
										meta: {
											requireAuth: true,
											role:'PROVINSI.KAPANEWON.KABUPATEN/KOTA',
											level:'ADMINISTRATOR.KAPANEWON.DINAS NAKER.DISNAKERTRANS DIY',
											title: 'Verifikai Administrasi Proposal'
										},
										props: true
									},
									{
										path: `:id`,
										name: 'administrative_verification',
										component: () => import('@/components/verification/administrative/Verify.vue'),
										meta: {
											requireAuth: true,
											role:'PROVINSI.KAPANEWON.KABUPATEN/KOTA',
											level:'ADMINISTRATOR.KAPANEWON.DINAS NAKER.DISNAKERTRANS DIY',
											title: 'Verifikai Administrasi Proposal'
										},
										props: true
									},
								]
							},
							{
								path: `technical`,
								redirect: {
									name: 'list_atechnical_verification'
								},
								component: () => import('@/components/verification/technical/Main.vue'),
								children: [
									{
										path: ``,
										name: 'list_technical_verification',
										component: () => import('@/components/verification/technical/Home.vue'),
										meta: {
											requireAuth: true,
											role:'PROVINSI.KABUPATEN/KOTA',
											level:'ADMINISTRATOR.DINAS NAKER.DISNAKERTRANS DIY',
											title: 'Verifikai teknis Proposal'
										},
										props: true
									},
									{
										path: `:id`,
										name: 'technical_verification',
										component: () => import('@/components/verification/technical/Verify.vue'),
										meta: {
											requireAuth: true,
											role:'PROVINSI.KABUPATEN/KOTA',
											level:'ADMINISTRATOR.DINAS NAKER.DISNAKERTRANS DIY',
											title: 'Verifikai Teknis Proposal'
										},
										props: true
									},
								]
							},
							{
								path: `recap/administration`,
								name: 'administrative_verification_recap',
								component: () => import('@/components/verification/administrative/Recap.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KAPANEWON.KABUPATEN/KOTA',
									level:'ADMINISTRATOR.KAPANEWON.DINAS NAKER.DISNAKERTRANS DIY',
									title: 'Verifikai Administrasi Proposal'
								},
								props: true
							},
							{
								path: `recap/technical`,
								name: 'technical_verification_recap',
								component: () => import('@/components/verification/technical/Recap.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KABUPATEN/KOTA',
									level:'ADMINISTRATOR.DINAS NAKER.DISNAKERTRANS DIY',
									title: 'Rekap Verifikai Teknis Proposal'
								},
								props: true
							},
						]
					},
					{
						path: `budget`,
						redirect: {
							name: 'budget'
						},
						component: () => import('@/components/budget/Main.vue'),
						children: [
							{
								path: ``,
								name: 'budget',
								component: () => import('@/components/budget/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR.DISNAKERTRANS DIY',
									title: 'Pengalokasian Anggaran'
								},
								props: true
							},
							{
								path: `create`,
								name: 'budget_create',
								component: () => import('@/components/budget/Create.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR.DISNAKERTRANS DIY',
									title: 'Pengalokasian Anggaran'
								},
								props: true
							},
							{
								path: `:id/create`,
								name: 'budget_update',
								component: () => import('@/components/budget/Update.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR.DISNAKERTRANS DIY',
									title: 'Pengalokasian Anggaran'
								},
								props: true
							},
						]
					},
					{
						path: `work/plan`,
						redirect: {
							name: 'work_plan'
						},
						component: () => import('@/components/work/plan/Main.vue'),
						children: [
							{
								path: ``,
								name: 'work_plan',
								component: () => import('@/components/work/plan/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Rencana Kerja'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'work_plan_list',
								component: () => import('@/components/work/plan/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Rencana Kerja'
								},
								props: true
							}
						]
					},
					{
						path: `work/plan`,
						redirect: {
							name: 'work_plan'
						},
						component: () => import('@/components/work/plan/Main.vue'),
						children: [
							{
								path: ``,
								name: 'work_plan',
								component: () => import('@/components/work/plan/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Rencana Kerja'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'work_plan_list',
								component: () => import('@/components/work/plan/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Rencana Kerja'
								},
								props: true
							}
						]
					},
					{
						path: `work/personnel`,
						redirect: {
							name: 'work_personnel'
						},
						component: () => import('@/components/work/personnel/Main.vue'),
						children: [
							{
								path: ``,
								name: 'work_personnel',
								component: () => import('@/components/work/personnel/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Tenaga Kerja'
								},
								props: true
							},
							{
								path: `:id/create`,
								name: 'work_personnel_create',
								component: () => import('@/components/work/personnel/Create.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Tenaga Kerja'
								},
								props: true
							},
							{
								path: `:id/profile`,
								name: 'work_personnel_detail',
								component: () => import('@/components/work/personnel/Detail.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Tenaga Kerja'
								},
								props: true
							},
							{
								path: `:id/update`,
								name: 'work_personnel_update',
								component: () => import('@/components/work/personnel/Update.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Tenaga Kerja'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'work_personnel_list',
								component: () => import('@/components/work/personnel/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Tenaga Kerja'
								},
								props: true
							}
						]
					},
					{
						path: `material`,
						redirect: {
							name: 'material'
						},
						component: () => import('@/components/material/Main.vue'),
						children: [
							{
								path: ``,
								name: 'material',
								component: () => import('@/components/material/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `:id/create`,
								name: 'material_create',
								component: () => import('@/components/material/Create.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Pengadaan Bahan Material'
								},
								props: true
							},
							{
								path: `:id/profile`,
								name: 'material_detail',
								component: () => import('@/components/material/Detail.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Pengadaan Bahan Material'
								},
								props: true
							},
							{
								path: `:id/update`,
								name: 'material_update',
								component: () => import('@/components/material/Update.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Pengadaan Bahan Material'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'material_list',
								component: () => import('@/components/material/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Pengadaan Bahan Material'
								},
								props: true
							}
						]
					},
					{
						path: `progress`,
						redirect: {
							name: 'progress'
						},
						component: () => import('@/components/progress/Main.vue'),
						children: [
							{
								path: ``,
								name: 'progress',
								component: () => import('@/components/progress/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Progres Harian'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'progress_list',
								component: () => import('@/components/progress/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Progres Harian'
								},
								props: true
							}
						]
					},
					{
						path: `payment`,
						redirect: {
							name: 'payment'
						},
						component: () => import('@/components/payment/Main.vue'),
						children: [
							{
								path: ``,
								name: 'payment',
								component: () => import('@/components/payment/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Pembayaran UPK'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'payment_list',
								component: () => import('@/components/payment/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Pembayaran UPK'
								},
								props: true
							}
						]
					},
					{
						path: `physical/progress`,
						redirect: {
							name: 'physical_progress'
						},
						component: () => import('@/components/physical/Main.vue'),
						children: [
							{
								path: ``,
								name: 'physical_progress',
								component: () => import('@/components/physical/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'physical_progress_list',
								component: () => import('@/components/physical/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Progress Fisik'
								},
								props: true
							}
						]
					},
					{
						path: `work/result`,
						redirect: {
							name: 'work_result'
						},
						component: () => import('@/components/result/Main.vue'),
						children: [
							{
								path: ``,
								name: 'work_result',
								component: () => import('@/components/result/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'work_result_list',
								component: () => import('@/components/result/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Pencatatan Hasil Pekerjaan'
								},
								props: true
							}
						]
					},
					{
						path: `companion`,
						redirect: {
							name: 'companion'
						},
						component: () => import('@/components/companion/Main.vue'),
						children: [
							{
								path: ``,
								name: 'companion',
								component: () => import('@/components/companion/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `:proposal_id/update/:id`,
								name: 'companion_update',
								component: () => import('@/components/companion/Update.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Kegiatan Pendamping / Lanjutan'
								},
								props: true
							},
							{
								path: `:id/create`,
								name: 'companion_create',
								component: () => import('@/components/companion/Create.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Kegiatan Pendamping / Lanjutan'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'companion_list',
								component: () => import('@/components/companion/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KALURAHAN',
									level:'ADMINISTRATOR.KALURAHAN',
									title: 'Kegiatan Pendamping / Lanjutan'
								},
								props: true
							}
						]
					},
					{
						path: `monev`,
						redirect: {
							name: 'monev'
						},
						component: () => import('@/components/monev/Main.vue'),
						children: [
							{
								path: ``,
								name: 'monev',
								component: () => import('@/components/monev/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KAPANEWON.KABUPATEN/KOTA',
									level:'ADMINISTRATOR.KAPANEWON.PENDAMPING.PENDAMPING',
									title: 'Proposal'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'monev_list',
								component: () => import('@/components/monev/List.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI.KAPANEWON.KABUPATEN/KOTA',
									level:'ADMINISTRATOR.KAPANEWON.PENDAMPING.PENDAMPING',
									title: 'Monev'
								},
								props: true
							}
						]
					},
					{
						path: `report`,
						redirect: {
							name: 'report'
						},
						component: () => import('@/components/report/Main.vue'),
						children: [
							{
								path: ``,
								name: 'report',
								component: () => import('@/components/report/Home.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR.DISNAKERTRANS DIY',
									title: 'Daftar Rekapitulasi Proposal'
								},
								props: true
							},
							{
								path: `:id`,
								name: 'report_detail',
								component: () => import('@/components/report/Detail.vue'),
								meta: {
									requireAuth: true,
									role:'PROVINSI',
									level:'ADMINISTRATOR.DISNAKERTRANS DIY',
									title: 'Detail Proposal'
								},
								props: true
							}
						]
					},
				]
			}
		]
	},
	{ 
		path: '/:pathMatch(.*)*', 
		redirect:{name:'NotFound'}, 
		component: () => import('@/components/error/Notfound.vue'),
		children:[
			{
				path: `/errors/notfound`,
				name: 'NotFound',
				component: () => import('@/components/error/Notfound.vue'),
				props: true,
				meta: {
					requireAuth: true,
					role: 'ALL',
					level: 'ALL',
					title:'Data Pengajuan BUP'
				},
			}
		]
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeEach(async (to, from, next) => {
	const auth  = useAuth();
	const scope = useScope();
	const token = await auth.getToken();

	NProgress.start()
	NProgress.set(0.1)
	NProgress.configure({
		ease: 'ease',
		speed: 500
	});

	if (to.meta.requireAuth) {
		if (!token) {
			return next({ name: 'signin' });
		}

		if (!scope.fors(to.meta.role, to.meta.level)) {
			return next({ name: 'NotFound' });
		}
	}

	if (to.meta.authPage) {
		if (token) {
			return next({ name: 'dashboard' });
		}
	}

	next();
});

router.afterEach(() => {
	NProgress.done()
});

export default router